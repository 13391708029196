<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <g>
      <polygon
        style="fill:#FED159;"
        points="221.279,256 34.721,256 0,398.222 256,398.222 	"
      />
      <polygon
        style="fill:#FED159;"
        points="477.279,256 290.721,256 256,398.222 512,398.222 	"
      />
      <polygon
        style="fill:#FED159;"
        points="349.279,113.778 162.721,113.778 128,256 384,256 	"
      />
    </g>
    <g>
      <polygon
        style="fill:#F6C454;"
        points="162.721,113.778 128,256 167.111,256 201.832,113.778 	"
      />
      <polygon
        style="fill:#F6C454;"
        points="34.721,256 0,398.222 39.111,398.222 73.832,256 	"
      />
      <polygon
        style="fill:#F6C454;"
        points="290.721,256 256,398.222 295.111,398.222 329.832,256 	"
      />
    </g>
    <g>
      <polygon
        style="fill:#FFE4A9;"
        points="349.279,113.778 384,256 344.889,256 310.168,113.778 	"
      />
      <polygon
        style="fill:#FFE4A9;"
        points="477.279,256 512,398.222 472.889,398.222 438.168,256 	"
      />
      <polygon
        style="fill:#FFE4A9;"
        points="221.279,256 256,398.222 216.889,398.222 182.168,256 	"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
